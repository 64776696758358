import { redirect } from 'vike/abort'
import { useProfileStore } from "@/pages/cs/profil/profileStore.ts";
import { useBusinessRequestStore } from "@/pages/cs/(businessrequest)/businessRequestStore";
import useItemUpdates from "@/stores/proposalItemUpdates.ts";
import useWorkDetailStore from "@/stores/workdetail.ts";

export async function guard(pageContext: any) {

    if (pageContext.urlOriginal === '/' && pageContext.urlPathname != "/") {
        throw redirect(pageContext.urlPathname)
    }

    const profileStore = useProfileStore()
    const businessRequestStore = useBusinessRequestStore()
    const itemStore = useItemUpdates()
    const workDetailStore = useWorkDetailStore()

    await profileStore.handleSaveChangesBeforeLeaving(pageContext)
    await businessRequestStore.handleSaveChangesBeforeLeaving(pageContext)
    await itemStore.handleSaveChangesBeforeLeaving(pageContext)
    await workDetailStore.handleSaveChangesBeforeLeaving(pageContext)
}